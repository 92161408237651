<template>
  <div class="pricing-schedule-container">
    <div class="pricing-container">
      <div class="pricing-item">
        <img src="../assets/icons/skirt.png" class="pricing-image" />
        <h3>Skirts</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/blouse.png" class="pricing-image" />
        <h3>Blouse</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/sweater.png" class="pricing-image" />
        <h3>Sweater</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/L-Shirt.png" class="pricing-image" />
        <h3>L-Shirt</h3>
        <p>$2.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/overcoat.png" class="pricing-image" />
        <h3>Over Coat</h3>
        <p>$16.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/coat.png" class="pricing-image" />
        <h3>3/4 Coat</h3>
        <p>$10.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/dress.png" class="pricing-image" />
        <h3>Dress</h3>
        <p>$9.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/short.png" class="pricing-image" />
        <h3>Short</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/t-shirt.png" class="pricing-image" />
        <h3>T-Shirt</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/dress shirt.png" class="pricing-image" />
        <h3>Dress Shirt</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/vest.png" class="pricing-image" />
        <h3>Vest</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/2PC Suit.png" class="pricing-image" />
        <h3>2PC Suit</h3>
        <p>$11.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/3PC Suit.png" class="pricing-image" />
        <h3>3PC Suit</h3>
        <p>$16.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/jacket.png" class="pricing-image" />
        <h3>Regular Jacket</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/sports jacket.png" class="pricing-image" />
        <h3>Sport Jacket</h3>
        <p>$9.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/tie.png" class="pricing-image" />
        <h3>Tie</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/jeans.png" class="pricing-image" />
        <h3>Pants</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/tuxedo.png" class="pricing-image" />
        <h3>Tuxedo</h3>
        <p>$12.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/scarf.png" class="pricing-image" />
        <h3>Scarf</h3>
        <p>$5.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/jumpsuit.png" class="pricing-image" />
        <h3>Jump Suit</h3>
        <p>$10.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/comfort queen.png" class="pricing-image" />
        <h3>Comfort Queen</h3>
        <p>$29.99</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/bed.png" class="pricing-image" />
        <h3>BedSpread Twin</h3>
        <p>$25.00</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/tablecloth.png" class="pricing-image" />
        <h3>TableCloth Mid</h3>
        <p>$15.00+up</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/towel.png" class="pricing-image" />
        <h3>Blanket Mid</h3>
        <p>$28.00</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/window.png" class="pricing-image" />
        <h3>Valance</h3>
        <p>$15.00+up</p>
      </div>
      <div class="pricing-item">
        <img src="../assets/icons/textile.png" class="pricing-image" />
        <h3>Pillow Case</h3>
        <p>$3.99</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingContent",
};
</script>

<style scoped>
.pricing-schedule-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.pricing-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px; /* Space between items */
  align-items: center;
  justify-content: center;
}

.pricing-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pricing-image {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

.pricing-item h3 {
  font-size: 0.8rem;
  color: #333;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
}

.pricing-item p {
  font-size: 0.7rem;
  color: #555;
  font-family: "Noto Sans", sans-serif;
}

@media (max-width: 768px) {
  .pricing-schedule-container {
    flex-direction: column;
    align-items: center;
  }

  .pricing-container {
    justify-content: center;
  }
}
</style>
