<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import NavBar from './components/NavBar.vue'

export default {
  name: "App",
  // components: {
  //   NavBar
  // }
}
</script>

<style scoped>
*{
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}</style>
