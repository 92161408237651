<template>
    <div>
        <NavBar />
        <Swiper :modules="modules" :autoplay="{
            delay: 3000,
            disableOnInteraction: false
        }" :effect="'fade'" class="image-swiper">
            <SwiperSlide><img src="../assets/laundry.jpg" alt="Image 2" /></SwiperSlide>
            <SwiperSlide><img src="../assets/delivery.jpg" alt="Image 2" /></SwiperSlide>
            <SwiperSlide><img src="../assets/drycleaning.jpg" alt="Image 2" /></SwiperSlide>
            <SwiperSlide><img src="../assets/bg13.avif" alt="Image 2" /></SwiperSlide>
            <SwiperSlide><img
                    src="../assets/tailoring.jpg"
                    alt="Image 2" /></SwiperSlide>
        </Swiper>
        <div class="text-overlay">
            <h3>Premium Care with Zero Delivery Fees</h3>
            <p class="fst-italic">
                Enjoy top-tier laundry services with no hidden charges. From easy scheduling to pristine results, we
                handle your clothes with precision and care—all with free delivery.
            </p>
            <ul>
                <li><i class="bi bi-check-circle"></i> Convenient Pickup & Delivery
                    We come to you at no extra cost.
                </li>
                <li><i class="bi bi-check-circle"></i> Expert Care for Every Garment
                    Delicate fabrics or tough stains, we treat every piece with attention.
                </li>
                <li><i class="bi bi-check-circle"></i> Swift 2-Day Turnaround
                    Need it fast? Your laundry is cleaned and returned in just 2 days.</li>
            </ul>
            <router-link to="/schedule">
                <a href="#" class="read-more"><span>Schedule Your Pickup </span><i
                        class="bi bi-arrow-up-right-circle-fill"></i></a>
            </router-link>
        </div>
        <FooTer />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/modules'
import 'swiper/css/bundle';
import "swiper/css/pagination"
import "swiper/css/autoplay"
import NavBar from './NavBar.vue';
import FooTer from './Footer.vue';

export default {
    name: "HomePage",
    components: {
        NavBar,
        FooTer,
        Swiper,
        SwiperSlide
    },
    setup() {
        return {
            modules: [Pagination, Navigation, Autoplay, EffectFade]
        };
    },
}
</script>

<style scoped>
.image-swiper {
    width: 100%;
    height: 100vh;
}

.image-swiper .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    position: absolute;
    top: 40%;
    left: 15%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    z-index: 1;
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards 0.8s;
    box-sizing: border-box;
}

.text-overlay h3 {
    font-size: clamp(1.5rem, 2vw + 1rem, 2.5rem);
    margin-bottom: 1rem;
    color: #0a57a2;
    line-height: 1.2;
    padding: 0 1rem;
}

.text-overlay p {
    font-size: clamp(0.9rem, 1vw + 0.5rem, 1.1rem);
    padding: 0 1rem;
    max-width: 600px;
}

.text-overlay ul {
    list-style: none;
    padding: 1rem;
}

.text-overlay ul li {
    font-size: clamp(1rem, 1.2vw, 1.2rem);
    margin-bottom: 0.5rem;
}

.text-overlay ul li i {
    color: #0a57a2;
    margin-right: 10px;
}

.text-overlay .read-more {
    display: inline-block;
    color: #0a57a2;
    font-size: clamp(1rem, 1.5vw, 2rem);
    font-weight: bold;
    text-decoration: none;
    border: 2px solid #0a57a2;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    transition: background 0.3s, color 0.3s, border-color 0.3s;
}

.text-overlay .read-more:hover {
    background: #0a57a2;
    color: white;
    border-color: #0a57a2;
}

/* h3+p, h3+p+ul{
    color: black;
    font-weight: bold;
} */

@media (max-width: 576px) {
    .navbar {
        padding: 0.5rem;
    }

    .navbar-brand {
        font-size: 1.2rem;
    }
}


@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 786px) {
    .text-overlay {
        top: 25%;
        left: 5%;
    }

    .text-overlay p {
        max-width: max-content;
    }
}
</style>