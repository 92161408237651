<template>
    <div class="services-schedule-container">
        <div class="services-container">
            <div class="service-item">
                <img src="../assets/washing-clothes.png" alt="Washing/Cleaning" class="service-image" />
                <h3>Laundry</h3>
            </div>
            <div class="service-item">
                <img src="../assets/dry-cleaning.png" alt="Dry Cleaning" class="service-image" />
                <h3>Dry Cleaning</h3>
            </div>
            <!-- <div class="service-item">
                <img src="../assets/iron.png" alt="Pressing" class="service-image" />
                <h3>Pressing</h3>
            </div> -->
            <div class="service-item">
                <img src="../assets/suit.png" alt="Alterations" class="service-image" />
                <h3>Tailoring</h3>
            </div>
        </div>
        <div class="schedule-pickup">
            <p>Let us handle the dirty work.</p>
            <button @click="goToSchedulePage" class="schedule-button">Schedule Your Pickup</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceContent",
    methods: {
        goToSchedulePage() {
            this.$router.push('/schedule');
        }
    }
};
</script>

<style scoped>
.services-schedule-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.services-container {
    display: flex;
    gap: 100px;
    align-items: center;
}

.service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-image {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}

.service-item h3 {
    font-size: 0.7rem;
    color: #333;
    margin: 0;
}

.schedule-pickup {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.schedule-button {
    border: 1px solid #0a57a2
;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    color: #0a57a2
;
    background: none;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.schedule-button:hover{
    border: none;
    background-color: #0a57a2
;
    color: black;
}


@media (max-width: 768px) {
    .services-schedule-container {
        flex-direction: column;
        align-items: center;
    }

    .services-container {
        flex-wrap: wrap;
        justify-content: center;
    }


    .schedule-button {
        margin-top: 20px;
    }
}
</style>