<template>
  <nav>
    <NavBar />
  </nav>
  <div class="admin-portal">
    <!-- Vertical Navigation -->
    <nav class="vertical-nav">
      <ul>
        <!-- Dashboard -->
        <li @click="switchSection('dashboard')" :class="{ active: currentSection === 'dashboard' }">
          Dashboard
        </li>
        <!-- Services -->
        <li @click="switchSection('services')" :class="{ active: currentSection === 'services' }">
          Services
        </li>
        <!-- Add Customer -->
        <li @click="switchSectionAndRedirect" :class="{ active: currentSection === 'addCustomer' }">
          Place Order
        </li>
        <!-- Logout -->
        <li @click="showLogoutModal" :class="{ active: currentSection === 'logout' }">
          Logout
        </li>
      </ul>
    </nav>


    <!-- Main Content Area -->
    <div class="main-content">
      <div v-if="currentSection === 'dashboard'" class="dashboard">
        <h1>Dashboard</h1>
        <p><strong>Total Orders:</strong> {{ totalOrders }}</p>
        <div class="stats">
          <div class="stats-content">
            <ChartContent />
          </div>
        </div>

        <!-- Filters Section -->
        <h3>Search orders using filters</h3>
        <div class="filters">
          <div>
            <label for="cityFilter">City:</label>
            <select v-model="selectedCity" @change="filterOrders" id="cityFilter">
              <option value="">All Cities</option>
              <option v-for="city in uniqueCities" :key="city" :value="city">{{ city }}</option>
            </select>
          </div>

          <div>
            <label for="orderTypeFilter">Order Type:</label>
            <select v-model="selectedOrderType" @change="filterOrders" id="orderTypeFilter">
              <option value="">All Types</option>
              <option v-for="type in uniqueOrderTypes" :key="type" :value="type">{{ type }}</option>
            </select>
          </div>

          <div>
            <label for="pickupStatusFilter">Pickup Status:</label>
            <select v-model="selectedPickupStatus" @change="filterOrders" id="pickupStatusFilter">
              <option value="">All Statuses</option>
              <option v-for="status in uniquePickupStatuses" :key="status" :value="status">{{ status }}</option>
            </select>
          </div>

          <div>
            <label for="statusFilter">Order Status:</label>
            <select v-model="selectedStatus" @change="filterOrders" id="statusFilter">
              <option value="">All Statuses</option>
              <option v-for="status in uniqueOrderStatuses" :key="status" :value="status">{{ status }}</option>
            </select>
          </div>
        </div>

        <!-- Order Table -->
        <div class="orders-table-wrapper">
          <table v-if="orders.length" class="orders-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Phone Number</th>
                <th>City</th>
                <th>Pickup Date</th>
                <th>Delivery Date</th>
                <th>Delivery Address</th>
                <th>Order Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in filteredOrders" :key="order.id" @click="goToUpdateOrder(order)"
                :class="{ clickable: order.orderStatus != 'DELIVERED' }">
                <td data-label="Order ID">{{ order.id }}</td>
                <td data-label="Customer Name">{{ order.customerName }}</td>
                <td data-label="Phone Number">{{ order.phoneNumber }}</td>
                <td data-label="City">{{ order.city }}</td>
                <td data-label="Pickup Date">{{ formatDate(order.pickupDate) }}</td>
                <td data-label="Delivery Date">{{ order.deliveryDate ? formatDate(order.deliveryDate) : 'N/A' }}</td>
                <td data-label="Delivery Address">{{ order.address }}</td>
                <td data-label="Order Status"
                  :style="{ color: getOrderStatusColor(order.orderStatus), fontWeight: 'bold' }">
                  {{ order.orderStatus }}
                </td>

              </tr>
            </tbody>
          </table>

          <div class="pagination">
            <button @click="prevPage" :disabled="currentPage === 0">Previous</button>
            <span>Page {{ currentPage + 1 }} of {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage >= totalPages - 1">Next</button>
          </div>
        </div>
      </div>


      <div v-if="currentSection === 'services'" class="services">
        <h1>Services</h1>
        <ul>
          <li>Pickup Service</li>
          <li>Dry Cleaning</li>
          <li>Express Service</li>
          <li>Tailoring</li>
          <li>Delivery Service</li>
        </ul>
      </div>

      <!-- Update Order Section -->
      <div v-if="showUpdateModal" class="modal-backdrop">
        <div class="order-modal">
          <img class="close-btn" @click="showUpdateModal = false" src="../assets/icons/close.png" alt="Close">
          <div class="current-order">
            <h3>Current Order Details</h3>
            <p><strong>Order Status:</strong> {{ selectedOrder.orderStatus }}</p>
            <p><strong>Pickup Status:</strong> {{ selectedOrder.pickupStatus }}</p>
            <p><strong>Pickup Issue:</strong> {{ selectedOrder.pickupIssue || 'None' }}</p>
          </div>
          <div class="update-order">
            <h3>Update the order {{ selectedOrder ? selectedOrder.id : '' }} here</h3>
            <div>
              <label for="status">Status:</label>
              <select v-model="orderStatus" id="status" required>
                <option value="CREATED">Created</option>
                <option value="IN_PROGRESS">In Progress</option>
                <option value="DELIVERED">Delivered</option>
                <option value="CANCELLED">Cancelled</option>
              </select>
            </div>
            <div>
              <label for="pickupStatus">Pickup Status:</label>
              <select v-model="pickupStatus" id="pickupStatus" required>
                <option value="PENDING">Pending</option>
                <option value="COMPLETED">Completed</option>
                <option value="NOT_RESPONDED">Not Responded</option>
                <option value="FAILED">Failed</option>
              </select>
            </div>
            <div>
              <label for="pickupIssue">Pickup Issue:</label>
              <input type="text" v-model="pickupIssue" id="pickupIssue" />
            </div>
          </div>
          <div class="update-order">
            <form @submit.prevent="uploadImage">
              <div v-if="selectedOrder">
                <h3>Upload an Image for Order {{ selectedOrder ? selectedOrder.id : '' }}</h3>
                <input type="file" @change="handleFileUpload" accept="image/*" required>
                <div class="button-container">
                  <button type="submit">Update Order with receipt</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


      <!-- Add Customer Section -->
      <!-- <div v-if="currentSection === 'addCustomer'" class="add-customer">
        <h1>Add Customer</h1>
        <AddCustomer />
      </div> -->
    </div>

    <!-- Logout Confirmation Modal -->
    <div v-if="showLogout" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Confirm Logout</h2>
        <p>Are you sure you want to logout?</p>
        <button @click="logout">Yes, Logout</button>
        <button @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
  <MessageModal :isVisible="isModalVisible" :status="modalStatus" :title="modalTitle" :message="modalMessage"
    @close="isModalVisible = false" />
</template>

<script>
import axios from 'axios'
import NavBar from './NavBar.vue';
import MessageModal from './messageModal.vue';
import ChartContent from './ChartContent.vue';
// import AddCustomer from './AddCustomer.vue';

export default {
  data() {
    return {
      showUpdateModal: false,
      orders: [],
      currentPage: 0,
      pageSize: 10,
      totalOrders: 0,
      totalPages: 0,
      filteredOrders: [],
      currentSection: "dashboard",
      showLogout: false,
      newCustomer: {
        name: "",
        phoneNumber: "",
        fullAddress: "",
        area: "",
        zip: "",
        state: "OH",
        country: "USA"
      },
      selectedCity: '',
      selectedOrderType: '',
      selectedPickupStatus: '',
      selectedStatus: '',
      uniqueCities: [],
      uniqueOrderTypes: [],
      uniquePickupStatuses: [],
      uniqueOrderStatuses: [],
      orderId: null,
      selectedOrder: null,
      orderStatus: '',
      pickupIssue: '',
      pickupStatus: '',
      imageFile: null,
      items: [
      ],
      itemDetails: {
        'L-Shirt(L/S)': { id: 1, price: 2.99 },
        'Skirt': { id: 2, price: 5.99 },
        'Blouse': { id: 3, price: 5.99 },
        'Sweater': { id: 4, price: 5.99 },
        'Pants': { id: 5, price: 5.99 },
        'Over Coat': { id: 6, price: 15.99 },
        '3/4 Coat': { id: 7, price: 10.99 },
        'Dress': { id: 8, price: 9.99 },
        'Short': { id: 9, price: 5.99 },
        'T-Shirt': { id: 10, price: 5.99 },
        'Dress Shirt': { id: 11, price: 5.99 },
        'Vest': { id: 12, price: 5.99 },
        '2PC Suit': { id: 13, price: 10.99 },
        '3PC Suit': { id: 14, price: 16.99 },
        'Regular Jacket': { id: 15, price: 5.99 },
        'Sport Jacket': { id: 16, price: 9.99 },
        'Tie': { id: 17, price: 5.99 },
        'Jeans': { id: 18, price: 5.99 },
        'Tuxedo': { id: 19, price: 9.99 },
        'Scarf': { id: 20, price: 5.99 },
        'Comfort Queen': { id: 21, price: 29.99 }
      }

    };
  },
  components: {
    NavBar,
    MessageModal,
    ChartContent,
    // AddCustomer
  },
  methods: {
    getOrderStatusColor(status) {
      switch (status) {
        case 'IN_PROGRESS':
          return '#f39c12';
        case 'DELIVERED':
          return '#27ae60';
        case 'CREATED':
          return '#3498db';
        case 'CANCELLED':
          return '#e74c3c';
      }
    },
    switchSectionAndRedirect() {
      this.$router.push('/schedule');
    },
    async fetchOrders(page) {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          this.showModal(
            "Session Expired",
            "You need to log in to continue. Redirecting...",
            "failure"
          );
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
          return;
        }

        const response = await fetch(`https://laundry-service-maxcleaners-efc6a9ef36e0.herokuapp.com/maxcleaners/fetchOrders?page=${page}&size=${this.pageSize}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });

        // Check if the response status is not OK (non-2xx status codes)
        if (!response.ok) {
          const errorData = await response.json();

          // console.log(response.status);


          if (response.status === 401 || response.status === 403) {
            // Token missing or expired
            if (errorData?.errorDetails?.errorMessage === 'Missing or invalid token') {
              this.showModal(
                "Session Expired",
                "Your session is expired, please log in again. Redirecting...",
                "failure"
              );
            } else if (errorData?.errorDetails?.errorMessage === 'Only admin can view orders') {
              this.showModal(
                "Unauthorized Access",
                "You do not have permission to view this page. Redirecting...",
                "warning"
              );
              setTimeout(() => {
                this.$router.push('/login');
              }, 2000);
            }
          } else {
            // General API error handling for other status codes
            alert(errorData?.message)
            // this.showModal(
            //   "Error",
            //   `Something went wrong: ${errorData?.message || 'Unable to fetch orders'}`,
            //   "failure"
            // );
          }
          // return;
        }

        // Parse the JSON data
        const data = await response.json();

        // Check for specific API response errors
        if (!data?.data?.orders) {
          this.showModal(
            "Session Expired",
            "Your session is expired, please log in again. Redirecting...",
            "failure"
          );
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
          return;
        }

        // If all goes well, update orders and pagination
        this.totalOrders = data?.data?.total;
        this.orders = data?.data?.orders;
        this.filteredOrders = this.orders;
        // console.log(this.filteredOrders[0]);

        this.extractUniqueFilters();
        this.totalPages = Math.ceil(this.totalOrders / this.pageSize);
      } catch (error) {
        // Network or unexpected errors
        console.error('Error fetching orders:', error);
        // this.showModal(
        //   "Network Error",
        //   "Unable to fetch orders. Please check your internet connection or try again later.",
        //   "failure"
        // );
      }
    },
    showModal(title, message, status) {
      this.modalTitle = title;
      this.modalMessage = message;
      this.modalStatus = status;
      this.isModalVisible = true;
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.fetchOrders(this.currentPage);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
        this.fetchOrders(this.currentPage);
      }
    },
    extractUniqueFilters() {
      this.uniqueCities = [...new Set(this.orders.map(order => order.city))];
      this.uniqueOrderTypes = [...new Set(this.orders.map(order => order.orderType))];
      this.uniquePickupStatuses = [...new Set(this.orders.map(order => order.pickupStatus))];
      this.uniqueOrderStatuses = [...new Set(this.orders.map(order => order.orderStatus))];
    },
    filterOrders() {
      this.filteredOrders = this.orders.filter(order => {
        return (
          (this.selectedCity === '' || order.city === this.selectedCity) &&
          (this.selectedOrderType === '' || order.orderType === this.selectedOrderType) &&
          (this.selectedPickupStatus === '' || order.pickupStatus === this.selectedPickupStatus) &&
          (this.selectedStatus === '' || order.orderStatus === this.selectedStatus)
        );
      });
    },
    fetchFilteredOrders() {
      this.filterOrders();
    },
    getCustomerName(customerId) {
      return `Customer ${customerId}`;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    switchSection(section) {
      this.currentSection = section;
    },
    goToUpdateOrder(order) {
      this.showUpdateModal = true,
        this.items = []
      this.selectedOrder = order;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageFile = file;
      }
    },
    async uploadImage() {
      const updatedOrder = {
        status: this.orderStatus,
        pickupStatus: this.pickupStatus,
        pickupIssue: this.pickupIssue
      };
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.put(`https://laundry-service-maxcleaners-efc6a9ef36e0.herokuapp.com/maxcleaners/updateOrder/${this.selectedOrder.id}`, updatedOrder, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        alert('Order updated successfully!', response.data);
        this.fetchOrders()
      } catch (error) {
        alert('Failed to update the order: ' + error.message);
      }
      if (!this.imageFile) {
        alert('Please select an image file to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.imageFile);

      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post(
          `https://laundry-service-maxcleaners-efc6a9ef36e0.herokuapp.com/maxcleaners/orders/${this.selectedOrder.id}/uploadImage`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        console.log(response);

        alert('Image uploaded successfully!');
        this.fetchOrders();
      } catch (error) {
        alert('Failed to upload the image: ' + error.message);
      }
    },
    // loadOrderDetails() {
    //   if (this.orderId) {
    //     this.selectedOrder = this.orders.find(order => order.id === this.orderId);
    //     if (this.selectedOrder) {
    //       this.orderStatus = this.selectedOrder.status;
    //       this.pickupStatus = this.selectedOrder.pickupStatus;
    //       this.pickupIssue = this.selectedOrder.pickupIssue || '';

    //       // Ensure items are defined before mapping
    //       this.items = this.selectedOrder.items ? this.selectedOrder.items.map(item => ({ ...item })) : [];
    //     } else {
    //       this.selectedOrder = null;
    //       alert('Order not found');
    //     }
    //   } else {
    //     this.selectedOrder = null;
    //     this.items = [];
    //   }
    // },
    // async updateOrder() {
    //   const updatedOrder = {
    //     items: this.items.map(item => ({
    //       id: item.id,
    //       name: item.name,
    //       quantity: item.quantity,
    //       remarks: item.remarks,
    //       price: item.price
    //     })),
    //     status: this.orderStatus,
    //     pickupStatus: this.pickupStatus,
    //     pickupIssue: this.pickupIssue
    //   };

    //   try {
    //     const token = localStorage.getItem('authToken');
    //     const response = await axios.put(`https://laundry-service-maxcleaners-efc6a9ef36e0.herokuapp.com/maxcleaners/updateOrder/${this.selectedOrder.id}`, updatedOrder, {
    //       headers: {
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type': 'application/json'
    //       }
    //     });
    //     alert('Order updated successfully!', response.data);
    //     this.fetchOrders()
    //   } catch (error) {
    //     alert('Failed to update the order: ' + error.message);
    //   }

    // },
    // Method to update the price when an item is selected
    // updateItemDetails(index) {
    //   const selectedItem = this.items[index].name;
    //   if (selectedItem) {
    //     const details = this.itemDetails[selectedItem];
    //     this.items[index].id = details.id;
    //     this.items[index].price = details.price * this.items[index].quantity;
    //   }
    // },
    // Method to update the price when the quantity changes
    // updateTotalPrice(index) {
    //   const selectedItem = this.items[index].name;
    //   if (selectedItem) {
    //     const details = this.itemDetails[selectedItem];
    //     this.items[index].price = details.price * this.items[index].quantity;
    //   }
    // },
    // addOrderItem() {
    //   this.items.push({ id: null, name: '', quantity: 1, price: 0, remarks: '' });
    // },
    // removeItem(index) {
    //   this.items.splice(index, 1);
    // },
    addCustomer() {
      this.newCustomer = {
        name: "",
        phoneNumber: "",
        fullAddress: "",
        area: "",
        zip: ""
      };
    },
    showLogoutModal() {
      this.showLogout = true;
    },
    closeModal() {
      this.showLogout = false;
    },
    logout() {
      localStorage.removeItem('authToken')
      this.$router.push('/login')
      this.closeModal();
    },
  },
  watch: {
    orderStatus(newStatus) {
      switch (newStatus) {
        case 'CREATED':
        case 'SCHEDULED':
          this.pickupStatus = 'PENDING';
          break;
        case 'IN_PROGRESS':
          this.pickupStatus = 'NOT_RESPONDED';
          break;
        case 'DELIVERED':
          this.pickupStatus = 'COMPLETED';
          break;
        case 'CANCELLED':
          this.pickupStatus = 'FAILED';
          break;
        default:
          this.pickupStatus = '';
      }
    }
  },
  mounted() {
    this.fetchOrders(this.currentPage);
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
}

/* Admin Portal Container */
.admin-portal {
  display: flex;
  height: 100vh;
  background-color: #f4f4f4;
}

/* Vertical Navigation */
.vertical-nav {
  width: 250px;
  /* background-color: black; */
  background: url(https://img.freepik.com/premium-photo/person-ironing-stack-folded-shirts-common-household-chore_153912-341797.jpg?ga=GA1.1.549336337.1726081730&semt=ais_hybrid) no-repeat;
  background-size: cover;
  color: white;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.vertical-nav ul {
  margin-top: 40%;
  list-style: none;
  padding: 0;
  width: 100%;
}

.vertical-nav li {
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  border-radius: 5px;
}

.vertical-nav li.active,
.vertical-nav li:hover {
  background-color: #0099cc;
  color: #ffffff;
}

/* Main Content Area */
.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard .stats {
  position: relative;
  padding: 50px 10px;
  font-size: 16px;
  color: #333;
  background: url('../assets/bga.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
}

.dashboard .stats::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.269);
  z-index: 1;
}

.dashboard .stats-content {
  position: relative;
  z-index: 2;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

.filters label {
  font-weight: 600;
  margin-right: 10px;
  color: #333;
}

.filters div {
  display: flex;
  flex-wrap: wrap;
  align-items: center
}

.filters select {
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filters select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.filters select option {
  padding: 10px;
}

.filters select:hover {
  border-color: #0056b3;
}

.filters select:disabled {
  background-color: #f1f1f1;
  cursor: not-allowed;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.orders-table th {
  background-color: #0a57a2;
  color: white;
}

.orders-table tr.clickable {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.orders-table tr.clickable:hover {
  background-color: #e0f7fa;
  /* Slightly darker on hover */
}

.orders-table tr:hover {
  background-color: #e0f7f7;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.order-modal {
  max-height: max-content;
  background-color: white;
  border-radius: 5px;
  max-width: 55%;
  max-height: 85%;
  overflow-y: scroll;
  padding: 10px;
}

.order-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  border-radius: 5px;
}

.current-order,
.update-order {
  flex: 1;
  margin-right: 20px;
}

.update-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item-card {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.item-select,
.item-input {
  flex: 1;
  /* padding: 0.5rem; */
  /* font-size: 1rem; */
  border-radius: 4px;
  border: 1px solid #ccc;
}

.price-readonly {
  background-color: #e9ecef;
}

.add-item {
  /* padding: 0.7rem 1.5rem; */
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  align-self: flex-start;
}

.add-item:hover {
  background-color: #218838;
}

.remove-item {
  padding: 0.5rem;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-item:hover {
  background-color: #c82333;
}

label {
  display: block;
  font-weight: 500;
}


.close-btn {
  width: 2%;
  /* margin: 3%; */
  float: right;
  cursor: pointer;
}

.update-order input[type="number"],
.update-order input[type="text"],
.update-order select {
  width: 50%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.update-order input[type="number"]:focus,
.update-order input[type="text"]:focus,
.update-order select:focus {
  border-color: #0a57a2;
  outline: none;
}

.update-order h3 {
  margin: 15px 0;
  font-size: 1.5rem;
  color: #444;
}

.update-order button {
  padding: 10px 15px;
  background-color: #0a57a2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
  margin: 10px;
}

.update-order button:hover {
  background-color: #0099bb;
}

.button-container {
  position: sticky;
}

.items-list {
  margin-top: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.items-list h4 {
  margin: 10px 0;
  font-size: 1.2rem;
  color: #333;
}


/* Services Styles */
.services {
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.services h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid #b23850;
  display: inline-block;
  padding-bottom: 5px;
}

.services ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.services li {
  font-size: 1.2rem;
  color: #555;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.services li:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Add Customer Form Styles */
.add-customer {
  width: 30%;
  margin: 5% auto;
}

.add-customer form {
  display: flex;
  flex-direction: column;
}

.add-customer form div {
  margin-bottom: 15px;
}

.add-customer label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.add-customer input,
.add-customer textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.add-customer input:focus,
.add-customer textarea:focus {
  border-color: #0a57a2;
  outline: none;
}

.add-customer button {
  padding: 10px;
  background-color: #0a57a2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-customer button:hover {
  background-color: #0099cc;
}

/* Modal Background Overlay */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: center;
}

/* Close Button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #555;
  transition: color 0.3s;
}

.close:hover {
  color: #b23850;
}

/* Headline */
.modal-content h2 {
  margin-top: 0;
  font-size: 1.8rem;
  color: #333;
}

/* Paragraph */
.modal-content p {
  font-size: 1rem;
  color: #555;
  margin: 15px 0;
}

/* Buttons */
.modal-content button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Logout Button */
.modal-content button:first-of-type {
  background-color: #b23850;
  color: #fff;
}

.modal-content button:first-of-type:hover {
  background-color: #922e41;
}

/* Cancel Button */
.modal-content button:last-of-type {
  background-color: #ddd;
  color: #333;
}

.modal-content button:last-of-type:hover {
  background-color: #bbb;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {

  .vertical-nav ul {
    margin: 0
  }

  .admin-portal {
    flex-direction: column;
  }

  .vertical-nav {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20%;
  }

  .main-content {
    padding: 10px;
  }

  .orders-table thead {
    display: none;
  }

  .orders-table tbody tr {
    display: block;
    margin-bottom: 20px;
  }

  .orders-table tbody tr td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
  }

  .orders-table tbody tr td::before {
    content: attr(data-label);
    font-weight: bold;
  }

  .services {
    padding: 15px;
  }

  .services h1 {
    font-size: 1.8rem;
  }

  .services li {
    font-size: 1rem;
  }

  .add-customer {
    width: 100%;
    padding: 10px;
  }

  .modal-content {
    width: 90%;
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 1.5rem;
  }

  .modal-content p {
    font-size: 0.9rem;
  }

  .modal-content button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
</style>
