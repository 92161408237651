<template>
  <NavBar />
  <div class="container">
    <div class="intro">
      <h1>Schedule your Pickup</h1>
      <p>Our experienced team is ready to handle your laundry with care.</p>
      <p>Schedule a convenient pickup time, and we'll
        take care of the rest.</p>
      <p> Choose from a variety of time slots, and we'll ensure your clothes are cleaned and
        delivered promptly.</p>
    </div>
    <div class="middle-container">
      <div class="calendar-container">
        <div class="header">
          <button id="arrow" @click="prevMonth" :disabled="!canGoToPrevMonth"><i class="bi bi-arrow-left-circle-fill"
              style="font-size: 1.5rem;"></i></button>
          <span class="calendar-heading">{{ currentMonthName }} {{ currentYear }}</span>
          <button id="arrow" @click="nextMonth" :disabled="!canGoToNextMonth"><i class="bi bi-arrow-right-circle-fill"
              style="font-size: 1.5rem;"></i></button>
        </div>
        <div class="calendar">
          <div class="day" v-for="(day, index) in daysOfWeek" :key="index">{{ day }}</div>
          <div v-for="(_, index) in firstDayOffset" :key="'empty-' + index" class="empty-date"></div>
          <div class="date" v-for="(date, index) in daysInCurrentMonth" :key="index"
            :class="{ disabled: isSunday(date) || isPastDate(date), 'last-week': isLastWeek(date), today: isToday(date), selected: isSelectedDate(date) }"
            @click="selectDate(date)">
            {{ date.getDate() }}
          </div>
        </div>
        <div v-if="selectedDate && !isSunday(selectedDate) && !isPastDate(selectedDate)" class="time-slots">
          <h3>Select a time slot for {{ formattedSelectedDateWithDay }}</h3>
          <div class="time-slot" v-for="(slot, index) in availableTimeSlots" :key="index">
            <button @click="selectTimeSlot(slot)" :disabled="isSlotDisabled(slot)"
              :class="{ 'selected-time': selectedTimeSlot === slot }">{{ slot }}</button>
          </div>

          <div class="proceed-container">
            <button @click="proceed" :disabled="!selectedTimeSlot">Proceed</button>
          </div>
        </div>
      </div>

      <div class="mid-right-cont">
        <div class="how-it-works">
          <h2>How It Works</h2>
          <p><i class="bi bi-caret-right-fill"></i> Select an available date from the calendar.</p>
          <p><i class="bi bi-caret-right-fill"></i> Choose a convenient time slot for pickup.</p>
          <p> <i class="bi bi-caret-right-fill"></i> Proceed to confirm your pickup schedule.</p>
          <p class="how-it-works-text">
            <img class="icon" src="../assets/icons/laundry-service.png" alt="">
            It's that simple! Our team will pick up your laundry, wash, dry, and deliver it back to you.
          </p>
        </div>

        <div class="service-highlights">
          <h2>Our Services</h2>
          <div class="services">
            <p><i class="bi bi-check-circle"></i> Express Delivery</p>
            <p><i class="bi bi-check-circle"></i> Washing</p>
            <p><i class="bi bi-check-circle"></i> Dry Cleaning</p>
            <p><i class="bi bi-check-circle"></i> Pressing</p>
            <p><i class="bi bi-check-circle"></i> Alterations</p>
          </div>
          <p class="help-text">
            <img class="icon" src="../assets/icons/help.png" alt="">
            Need pickup assistance?<br>
            Call us: +1 (614) 218-1183
          </p>
        </div>
      </div>

    </div>
  </div>
  <Footer />
</template>



<script>
import Footer from './Footer.vue';
import NavBar from './NavBar.vue';

export default {
  components: {
    NavBar,
    Footer
  },
  name: 'PickupCalendar',
  data() {
    return {
      currentDate: new Date(),
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      selectedDate: null,
      selectedTimeSlot: null,
      defaultTimeSlots: [
        "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM",
        "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM",
        "06:00 PM", "07:00 PM"
      ],
      timeSlots: [],
      daysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    };
  },
  computed: {
    availableTimeSlots() {
      if (this.selectedDate) {
        const day = this.selectedDate.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday
        if (day === 6) { // Saturday
          return this.defaultTimeSlots.slice(0, 10); // 8 AM to 5 PM
        }
      }
      return this.defaultTimeSlots; // Default time slots for other days
    },
    currentMonthName() {
      return new Date(this.currentYear, this.currentMonth).toLocaleString('default', { month: 'long' });
    },
    daysInCurrentMonth() {
      const days = [];
      const startOfMonth = new Date(this.currentYear, this.currentMonth, 1);
      const endOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);

      for (let date = new Date(startOfMonth); date <= endOfMonth; date.setDate(date.getDate() + 1)) {
        days.push(new Date(date)); // Clone the date to avoid mutating the original
      }
      return days;
    },
    firstDayOffset() {
      const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1).getDay();
      return (firstDayOfMonth === 0 ? 7 : firstDayOfMonth) - 1;
    },
    canGoToNextMonth() {
      const nextMonth = new Date(this.currentYear, this.currentMonth + 1);
      return nextMonth.getMonth() - this.currentDate.getMonth() <= 1 && nextMonth.getFullYear() === this.currentDate.getFullYear();
    },
    canGoToPrevMonth() {
      return this.currentMonth > this.currentDate.getMonth() || this.currentYear > this.currentDate.getFullYear();
    },
    formattedSelectedDateWithDay() {
      if (!this.selectedDate) return '';
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      const dateString = this.selectedDate.toLocaleDateString('en-US', options);
      return `${dateString.split(' ')[0]} ${dateString.split(' ').slice(1).join(' ')}`;
    }
  },
  methods: {
    selectTimeSlot(slot) {
      if (!this.isSlotDisabled(slot)) {
        this.selectedTimeSlot = slot;
        console.log(this.selectedTimeSlot);

      }
    },
    isSunday(date) {
      return date.getDay() === 0;
    },
    isLastWeek(date) {
      const endOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
      return date > new Date(endOfMonth.setDate(endOfMonth.getDate() - 7));
    },
    isToday(date) {
      const today = new Date();
      return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
    },
    isPastDate(date) {
      return date < this.currentDate.setHours(0, 0, 0, 0);
    },
    isSelectedDate(date) {
      return this.selectedDate && this.selectedDate.getTime() === date.getTime();
    },
    isSlotDisabled(slot) {
      if (!this.selectedDate) return false;
      const [hour, period] = slot.split(' ');
      let [hours, minutes] = hour.split(':').map(Number);
      if (period === 'PM' && hours !== 12) hours += 12;
      if (period === 'AM' && hours === 12) hours = 0;
      const slotDate = new Date(this.selectedDate);
      slotDate.setHours(hours, minutes, 0, 0);
      return slotDate < new Date();
    },
    nextMonth() {
      this.selectedTimeSlot = null
      if (this.canGoToNextMonth) {
        this.currentMonth++;
        if (this.currentMonth > 11) {
          this.currentMonth = 0;
          this.currentYear++;
        }
      }
    },
    prevMonth() {
      this.selectedTimeSlot = null
      if (this.canGoToPrevMonth) {
        this.currentMonth--;
        if (this.currentMonth < 0) {
          this.currentMonth = 11;
          this.currentYear--;
        }
      }
    },
    selectDate(date) {
      if (!this.isSunday(date) && !this.isPastDate(date)) {
        this.selectedDate = date;
        this.selectedTimeSlot = null;
      }
    },
    proceed() {
  if (this.selectedDate && this.selectedTimeSlot) {
    const [time, modifier] = this.selectedTimeSlot.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    // Convert to 24-hour format
    if (modifier === "PM" && hours < 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    // Extract year, month, and day from the selected date
    const year = this.selectedDate.getFullYear();
    const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(this.selectedDate.getDate()).padStart(2, '0');

    // Create a formatted date string in the desired format
    const formattedDateTime = `${year}-${month}-${day}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;

    this.$router.push({
      path: '/placeOrder',
      query: {
        date: formattedDateTime
      }
    });
  }
}

,
    getDaySuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }
  },
};
</script>


<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.intro {
  margin-top: 5%;
  margin-right: 27%;
}

.middle-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
  align-items: flex-start;
}

.calendar-container {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calendar-heading {
  font-size: 1.5rem;
  font-weight: bold
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.day,
.date,
.empty-date {
  padding: 20px 35px;
  text-align: center;
  border-radius: 5px;
}

.date.disabled {
  color: #ccc;
  pointer-events: none;
  background-color: #f5f5f5;
}

.date.today {
  background-color: #d4edda;
  font-weight: bold;
  border: 0.5px solid #28a745;
  box-shadow: 0 0 10px rgba(0, 128, 0, 0.3);
}

.date.selected {
  background-color: #0a57a2;
  color: white;
  font-weight: bold;
  box-shadow: 0 0 10px #0a57a2;
}

.date:hover {
  color: black;
  background-color: #e9ecef;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.time-slots {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.time-slot {
  display: inline-block;
}


.time-slot button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.time-slot button.selected-time {
  color: white;
  font-weight: bold;
  background-color: #0a57a2;
}

#arrow {
  color: #0a57a2;
  background: transparent;
}

button {
  border: none;
  background-color: #0a57a2;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-family: "Noto Sans", sans-serif;
}

button:hover {
  background-color: #01b5c5;
  transform: translateY(-2px);
}

button:disabled {
  background-color: #f9f9f9;
  border-color: #ccc;
  color: #aaa;
  cursor: not-allowed;
  opacity: 0.6;
}

.proceed-container {
  margin-top: 20px;
}

.proceed-container button {
  font-size: 1rem;
  color: white;
  font-weight: bold;
}

.how-it-works,
.service-highlights {
  width: 100%;
  max-width: 350px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: block;
  text-align: start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.intro h1,
.how-it-works h2,
.service-highlights h2,
.contact-info h2 {
  color: #0a57a2;
}

.how-it-works-text,
.help-text {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
}

.icon {
  width: 4rem;
  height: 4rem;
  margin-right: 10px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.services p {
  flex: 1 1 calc(50% - 20px);
  margin: 0;
  box-sizing: border-box;
}

.contact-info p {
  margin: 5px 0;
}


@media (max-width: 768px) {
  .container {
    padding-bottom: 0;
  }

  .intro {
    margin: 0 auto;
  }

  .middle-container {
    flex-direction: column;
    align-items: center;
  }

  .calendar {
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }

  .calendar-heading {
    font-size: 1rem;
    font-weight: bold
  }

  .day,
  .date,
  .empty-date {
    padding: 5px 5px;
  }

  .calendar-container {
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .time-slots {
    padding: 5px;
  }

  .time-slot {
    margin: 3px;
  }

  button {
    padding: 6px 6px;
    font-size: 14px;
  }

  .services p {
    flex: 1 1 100%;
  }

  .how-it-works,
  .service-highlights {
    padding: 10px;
  }
}
</style>