import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import HomePage from "./components/HomePage.vue";
import LoginForm from "./components/LoginForm.vue";
import SchedulePickup from "./components/SchedulePickup.vue";
import OrderHistory from "./components/OrderHistory.vue";
import AdminPortal from "./components/AdminPortal.vue";
import PlaceOrder from "./components/PlaceOrder.vue";
import store from "./store";
import { jwtDecode } from "jwt-decode";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/login",
    name: "LoginForm",
    component: LoginForm,
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: SchedulePickup,
  },
  {
    path: "/order-history",
    name: "OrderHistory",
    component: OrderHistory,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "AdminPortal",
    component: AdminPortal,
    meta: { requiresAuth: true },
  },
  {
    path: "/placeOrder",
    name: "PlaceOrder",
    component: PlaceOrder,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({ history: createWebHistory(), routes });

// Navigation guard to check authentication
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("authToken"); // Get token from localStorage
  const isAuthenticated = !!token; // Check if token exists

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!isAuthenticated) {
      next({ name: "LoginForm" });
    } else {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
          localStorage.removeItem("authToken");
          next({ name: "LoginForm" });
        } else {
          next();
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.removeItem("token");
        next({ name: "LoginForm" });
      }
    }
  } else {
    next();
  }
});

const app = createApp(App);
app.use(store);
app.use(router);

// Initializing authentication status on app load.
store.dispatch("initializeAuth");

app.mount("#app");
