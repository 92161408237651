<template>
  <div class="container">
    <div :class="['calendar-wrapper', { 'blurred': showLoader }]">
      <PickupCalendar v-if="showCalendar" />
    </div>
    <LoaderLogo v-if="showLoader" class="overlay" />
    <AddressModal v-if="!showLoader && showModal" @close-modal="closeModal" />
  </div>
</template>

<script>
import PickupCalendar from './PickupCalendar.vue';
import AddressModal from './AddressModal.vue';
import LoaderLogo from './LoaderLogo.vue';

export default {
  components: {
    PickupCalendar,
    AddressModal,
    LoaderLogo,
  },
  data() {
    return {
      showCalendar: true,
      showLoader: true,
      showModal: false,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    }
  },
  mounted() {
    //Timeout to hide the loader and show the modal after 2 seconds
    setTimeout(() => {
      this.showLoader = false;
      this.showModal = true;
    }, 2000);
  },
};
</script>

<style scoped>
.container {
  position: relative;
}

.calendar-wrapper {
  position: relative;
}

.calendar-wrapper.blurred {
  filter: blur(4px);
}

.overlay {
  position: absolute;
  z-index: 1;
}
</style>
