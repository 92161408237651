import createPersistedState from "vuex-persistedstate";

const state = {
  addressData: JSON.parse(localStorage.getItem("addressData")) || {
    address: "",
    secondary: "",
    city: "",
    state: "OH",
    zipcode: "",
  },
};

const mutations = {
  SET_ADDRESS(state, address) {
    state.addressData = address;
    localStorage.setItem("addressData", JSON.stringify(address));
  },
};

const actions = {
  updateAddress({ commit }, address) {
    commit("SET_ADDRESS", address);
  },
};

const getters = {
  getAddress: (state) => state.addressData,
};

const plugins = [
  createPersistedState({
    paths: ["addressData"],
  }),
];

export default {
  state,
  mutations,
  actions,
  getters,
  plugins,
};
