<template>
    <footer id="footer" class="footer dark-background">
        <div class="container footer-top">
            <div class="footer-grid">
                <div class="footer-about">
                    <div class="footer-company">
                        <div class="logo-container">
                            <img src="../assets/2.99_cleaners-removebg-preview.png" alt="" class="logo">
                            <h1>Max Cleaners</h1>
                        </div>
                        <p>Professional laundry services with 35 years of experience, ensuring your clothes get the care
                            they deserve.</p>
                    </div>
                    <div class="footer-findus">
                        <a href="index.html" class="sitename">Find us here!</a>
                        <div class="footer-contact">
                            <p>9864 Brewster Ln, Powell, OH 43065</p>
                            <p><strong>Phone:</strong> +1 (614) 218-1183</p>
                            <p><strong>Email:</strong> maxxcleaners1964@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div class="footer-links">
                    <div>
                        <h4>Useful Links</h4>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">About Us</a></li>
                            <!-- <li><a href="#">Services</a></li> -->
                            <li><a href="#" @click.prevent="showTermsModal = true">Terms of Service</a></li>
                            <!-- Make it true when everything's ready. -->
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div>
                        <h4>Our Services</h4>
                        <ul>
                            <li><a href="/schedule">Laundry</a></li>
                            <li><a href="/schedule">Tailoring</a></li>
                            <li><a href="/schedule">Dry Cleaning</a></li>
                            <li><a href="/schedule">Express Service</a></li>
                            <!-- <li><a href="/schedule">Pickup & Delivery Service</a></li> -->
                        </ul>
                        <TermsModal v-if="showTermsModal" :show="showTermsModal" @close="showTermsModal = false" />
                    </div>
                </div>
                <div class="google-maps">
                    <iframe
                        src="https://www.google.com/maps/embed/v1/place?q=9864+Brewster+Ln,+Powell,+OH+43065&key=AIzaSyCSWFS4hqto8CiMc8JQWTajPdYASOuHkSs"
                        frameborder="0" style="border:0; width: 100%; height: 400px;" allowfullscreen loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>

        <div class="container copyright">
            <p>©<strong class="sitename">Max Cleaners</strong>. All Rights Reserved</p>
            <div class="credits">
                Designed by <a href="https://smoke221.github.io/">iSmokeTechLabs</a>
            </div>
        </div>
    </footer>
</template>

<script>
import TermsModal from './TermsModal.vue';
export default {
    components: {
        TermsModal,
    },
    data() {
        return {
            showTermsModal: false,
        };
    },
    name: "FooTer",
};
</script>

<style scoped>
.footer {
    background-color: #1a1a1a;
    color: #f0f0f0;
    padding: 20px 10px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.footer-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    gap: 30px;
    max-width: 1200px;
    margin: auto;
}

.footer-grid>div {
    flex: 1;
    min-width: 300px;
    padding: 15px;
}

.footer-company h1 {
    color: #0a57a2;
    font-size: clamp(1.5rem, 2vw, 2.5rem);
}

.google-maps iframe {
    border-radius: 8px;
    width: 100%;
    border: 0;
}

.footer-findus .sitename {
    font-size: clamp(1.5rem, 2vw, 2rem);
    font-weight: 700;
    color: #0a57a2;
    text-decoration: none;
}

.footer-findus .footer-contact p {
    margin: 8px 0;
    font-size: clamp(0.9rem, 1vw, 1rem);
    color: #ddd;
}

.footer-links h4 {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    color: #0a57a2;
    margin-bottom: 15px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 10px;
}

.footer-links ul li a {
    color: #ddd;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links ul li a:hover {
    color: #0a57a2;
}

.copyright {
    border-top: 1px solid #444;
    text-align: center;
    padding: 10px;
    font-size: clamp(0.8rem, 1vw, 1rem);
}

.credits {
    font-size: clamp(0.7rem, 1vw, 0.9rem);
}

.credits a {
    color: #0a57a2;
    text-decoration: none;
}

.credits a:hover {
    text-decoration: underline;
}

.logo-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.logo {
    height: 80px;
    margin-right: 10px;
}

@media (max-width: 786px) {
    .footer-grid {
        padding: 5px;
        gap: 0;
    }

    .footer-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
</style>