<template>
    <div v-if="show" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
            <button class="close-button" @click="closeModal">&times;</button>
            <div class="terms-of-service">
                <h2>Terms of Service</h2>
                <p class="lastSync">Last Updated: October 16, 2024</p>
                <p>Welcome to Max Cleaners! These Terms of Service ("Terms") govern your use of our website and
                    services. By accessing or using our services, you agree to comply with and be bound by these Terms.
                    If you do not agree to these Terms, please do not use our services.</p>

                <h3>1. Services Provided</h3>
                <p>Max Cleaners offers laundry services including washing, drying, folding, ironing, and delivery. The
                    pickup and delivery times are as per your selection during the order process. Max Cleaners will make
                    reasonable efforts to meet these time slots.</p>

                <h3>2. Customer Responsibilities</h3>
                <p>Customers are responsible for providing accurate information for pickup and delivery locations.
                    Customers must ensure that laundry items are properly labeled and provide any specific washing
                    instructions. Max Cleaners is not responsible for items left in pockets or damage caused by
                    non-washable items (e.g., pens, lighters).</p>

                <h3>3. Payment Terms</h3>
                <p>Payment must be made at the time of order placement or upon delivery, as per the chosen payment
                    method. Prices for services are displayed on our website and may be subject to change. You will be
                    notified of any price changes before placing an order.</p>

                <h3>4. Lost or Damaged Items</h3>
                <p>Max Cleaners will take all reasonable precautions to avoid loss or damage to items. In the event of
                    loss or damage, Max Cleaners' liability is limited to an amount not exceeding ten times the charge
                    for cleaning the item. Claims for lost or damaged items must be reported within 48 hours of
                    receiving the delivery.</p>

                <h3>5. Cancellations & Refunds</h3>
                <p>You may cancel your order up to 24 hours before the scheduled pickup time for a full refund.
                    Cancellations made less than 24 hours before the scheduled pickup may be subject to a cancellation
                    fee. Refunds for services are at the discretion of Max Cleaners and will be processed to the
                    original payment method.</p>

                <h3>6. Changes to Services</h3>
                <p>Max Cleaners reserves the right to modify or discontinue any part of the services at any time without
                    notice. We will strive to inform customers of significant changes that affect their current or
                    future orders.</p>

                <h3>7. Governing Law</h3>
                <p>These Terms shall be governed and construed in accordance with the laws of [Your State/Country]. Any
                    disputes arising out of or in connection with these Terms shall be resolved through arbitration in
                    [City, State/Country].</p>

                <h3>8. Contact Us</h3>
                <p>If you have any questions or concerns about these Terms, please contact us at:</p>
                <ul>
                    <li>Email: maxxcleaners1964@gmail.com</li>
                    <li>Phone: +1 (614) 218-1183 </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
    /* Slight blur effect for the background */
}

.modal-content {
    background: #ffffff;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 80vh;
}

.modal-content::-webkit-scrollbar {
    width: 5px;
}

.modal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modal-content::-webkit-scrollbar-thumb {
    background: #888;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.close-button {
    background: #f5f5f5;
    border: none;
    font-size: 1.5rem;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    float: right;
    transition: background 0.3s ease;
}

.close-button:hover {
    background: #e0e0e0;
}

.lastSync {
    font-style: italic;
    /* text-align: center; */
    display: block;
    margin-bottom: 15px;
    color: #777;
}

.terms-of-service {
    color: #333;
    border-radius: 8px;
    margin: 0 auto;
}

.terms-of-service h2 {
    font-size: 1.75rem;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

.terms-of-service h3 {
    font-size: 1.2rem;
    color: #444;
    margin-top: 20px;
    margin-bottom: 10px;
}

.terms-of-service p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: #555;
}

.terms-of-service ul {
    list-style-type: none;
    padding: 0;
}

.terms-of-service ul li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
}

.terms-of-service ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #888;
}

@media (max-width: 600px) {
    .modal-content {
        width: 80%;
        padding: 15px;
        max-height: 60vh;
    }

    .close-button {
        width: 30px;
        height: 30px;
        font-size: 1.2rem;
    }

    .terms-of-service h2 {
        font-size: 1.5rem;
    }

    .terms-of-service h3 {
        font-size: 1.1rem;
    }
}
</style>