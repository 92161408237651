<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <span class="close-icon" @click="closeModal">&times;</span>
        <img v-if="status === 'success'" src="@/assets/icons/success_icon.png" alt="Success Icon" class="status-icon">
        <img v-if="status === 'failure'" src="@/assets/icons/fail_icon.png" alt="Error Icon" class="status-icon">
        <img v-if="status === 'warning'" src="@/assets/icons/warning.png" alt="Warning Icon" class="status-icon">
      </div>
      <h2 class="modal-title">{{ title }}</h2>
      <p class="modal-message">{{ message }}</p>
      <button class="re-btn" @click="redirect(true)" v-if="btnText">{{ btnText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageModal',
  props: {
    isVisible: { type: Boolean, required: true },
    title: { type: String, default: 'Notification' },
    message: { type: String, default: '' },
    status: { type: String, default: 'info' },
    btnText: { type: String, default:'OK' }
  },
  methods: {
    closeModal(shouldRedirect = false) {
      this.$emit('close', shouldRedirect);
    },
    redirect(goTo = false){
      this.$emit('go', goTo)
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  background: white;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  position: relative;
}

.close-icon {
  position: absolute;
  right: 5px;
  top: -15px;
  font-size: 2.5rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}

.close-icon:hover {
  color: #f44336;
}

.status-icon {
  width: 50px;
  height: 50px;
}

.modal-title {
  font-size: 22px;
  margin: 15px 0;
  font-weight: bold;
  color: #333;
}

.modal-message {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.re-btn {
  padding: 10px 20px;
  background-color: #0a57a2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.re-btn:hover {
  background-color: #524e8e;
}
</style>
