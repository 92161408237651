<template>
    <div id="donut">
        <apexchart v-if="series.length" type="donut" :options="chartOptions" :series="series" />
        <p v-else class="err-message">Please wait, loading data.....</p>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
    components: {
        apexchart: VueApexCharts,
    },
    setup() {
        const series = ref([]); // Initialize series to hold order counts
        const chartOptions = ref({
            chart: {
                type: 'donut',
            },
            labels: ['EXPRESS', 'GENERAL'], // Labels for the two types of orders
            responsive: [{
                breakpoint: 700,
                options: {
                    chart: {
                        width: 300,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        });

        const fetchOrderData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) {
                    throw new Error('Token is missing. Please log in again.');
                }

                const response = await fetch(
                    'https://laundry-service-maxcleaners-efc6a9ef36e0.herokuapp.com/maxcleaners/fetchOrders?size=200',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to fetch orders');
                }

                const data = await response.json();
                if (!data?.data?.orders) {
                    throw new Error('No orders found. Please try again later.');
                }

                // Count the number of each order type
                const orderTypesCount = data?.data?.orders.reduce((acc, order) => {
                    if (order.orderType === 'EXPRESS') {
                        acc.express += 1;
                    } else if (order.orderType === 'GENERAL') {
                        acc.standard += 1;
                    }
                    return acc;
                }, { express: 0, standard: 0 });

                // Update series with the counts
                series.value = [orderTypesCount.express, orderTypesCount.standard];

            } catch (error) {
                console.error('Error fetching chart data:', error);
                // Provide feedback to the user if an error occurs
                series.value = [];
            }
        };

        onMounted(() => {
            fetchOrderData();
        });

        return {
            series,
            chartOptions,
        };
    },
});
</script>

<style>
#donut {
    width: 30%;
}

.err-message {
    color: rgb(255, 255, 255);
    font-size: 16px;
    text-align: center;
    padding: 20px;
}

@media (max-width: 768px) {
    #donut {
        margin-right: 45%;
    }
}
</style>
