<template>
    <div class="loading-spinner"></div>
</template>
<script>
export default {
    name: "LoaderLogo",
}
</script>
<style scoped>
.loading-spinner {
    position: fixed;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    z-index: 1;
    background: url('../assets/2.99_cleaners-removebg-preview.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /* animation: spinning 1.5s infinite ease-in-out; */
}

.loading-spinner:after {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    background: transparent;
    border-radius: 100%;
    margin: -15px;
    box-shadow: 0 4px 0 0 #000;
    transition: all 1s linear;
    animation: lds-eclipse 1s linear infinite;
}

@keyframes spinning {
    0% {
        transform: translate(-50%, -50%) scale(1) translateZ(0);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.1) translateZ(0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1) translateZ(0);
    }
}

@keyframes lds-eclipse {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@media (max-width: 786px) {
    .loading-spinner{
        top: 15%;
    }
}
</style>
