<template>
    <div class="about-container">
        <img src="../assets/Firefly HD image of a Old South Korean couple working together in their modern laundry business, wit.jpg"
            alt="About Us" class="about-image" />
        <div class="text-content">
            <h1>From Our Family to Yours</h1>
            <p>Welcome to Max Cleaners, where family values and hard work have driven us for over 35 years. We started
                from humble beginnings after immigrating from South Korea, and our dedication helped us build a trusted
                dry cleaning service.</p>
            <p>From our early struggles, we’ve grown through unwavering commitment to quality and customer satisfaction.
                Every garment we clean reflects our reliability and care, strengthening the relationships we've built
                with our clients.</p>
            <p>As a family-run business, we prioritize trust and affordability. We’re excited to now offer free online
                pickup and delivery, continuing to serve our community with the same dedication that has defined our
                journey.</p>
            <p>Thank you for supporting us over the years. We look forward to serving you for many more!</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AboutContent',
}
</script>
<style scoped>
.about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.text-content {
    flex: 1;
    margin-left: 20px;
}

.about-container h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
}

.about-container p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
    margin-bottom: 15px;
}

.about-image {
    width: 40%;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

/* Media Queries */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column;
        align-items: center;
    }

    .text-content {
        margin-left: 0;
        margin-top: 20px;
    }

    .about-image {
        width: 80%;
    }
}
</style>