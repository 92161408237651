import Vuex from 'vuex';
import auth from './auth';
import address from './address';


export default new Vuex.Store({
  modules: {
    auth,
    address
  },
});
