<template>
    <nav :class="['navbar', { hovered: isHovered }]">
        <!-- <div class="nav-left-left"> -->
            <router-link to="/">
                <div class="nav-left">
                    <img src="../assets/2.99_cleaners-removebg-preview.png" alt="Logo" class="logo" />
                    <div class="name-container">
                        <span class="brand-name">Max Cleaners</span>
                        <span class="tagline">Free pickup & Quickly Delivered.</span>
                    </div>
                </div>
            </router-link>
            <button class="menu-toggle" @click="toggleMenu" v-if="isSmallScreen && !menuOpen">
                <i class="bi bi-list"></i>
            </button>
            <ul :class="['nav-center', { 'menu-open': menuOpen }]" v-if="!isAdminRoute || menuOpen"
                aria-label="Open Navigation Menu">
                <button class="close-menu" @click="toggleMenu" v-if="isSmallScreen && menuOpen"
                    aria-label="Close Navigation Menu">
                    <i class="bi bi-x"></i>
                </button>
                <li @mouseover="showBox('services')" @mouseleave="startHideTimer">
                    <a href="#services">Services</a> <i class="bi bi-caret-down-fill"></i>
                </li>
                <li @mouseover="showBox('pricing')" @mouseleave="startHideTimer">
                    <a href="#pricing">Pricing</a> <i class="bi bi-caret-down-fill"></i>
                </li>
                <li @mouseover="showBox('aboutus')" @mouseleave="startHideTimer">
                    <a href="#aboutus">About</a> <i class="bi bi-caret-down-fill"></i>
                </li>
                <li @mouseover="showBox('contact')" @mouseleave="startHideTimer">
                    <a href="#contact">Contact</a> <i class="bi bi-caret-down-fill"></i>
                </li>
            </ul>
        <!-- </div> -->
        <div v-if="!isAdminRoute" @click="handleAccountClick" class="nav-right">
            <router-link :to="accountLink"><i class="bi bi-person-circle"></i></router-link>
        </div>

        <div v-if="hoverContent && !isAdminRoute" class="hover-box" :class="{ 'active': hoverContent }"
            @mouseover="clearHideTimer" @mouseleave="startHideTimer">
            <ServicesContent v-if="hoverContent === 'services'" />
            <div v-if="hoverContent === 'pricing'">
                <PricesContent />
            </div>
            <div v-if="hoverContent === 'aboutus'">
                <AboutContent />
            </div>
            <div v-if="hoverContent === 'contact'">
                <ContactContent />
            </div>
        </div>
    </nav>
</template>


<script>
import { mapGetters } from 'vuex';
import AboutContent from './AboutContent.vue';
import ContactContent from './ContactContent.vue';
import PricesContent from './PricesContent.vue';
import ServicesContent from './ServicesContent.vue';
export default {
    name: "NavBar",
    components: {
        ServicesContent,
        AboutContent,
        ContactContent,
        PricesContent
    },
    data() {
        return {
            hoverContent: null,
            hideTimer: null,
            isHovered: false,
            menuOpen: false,
        };
    },
    computed: {
        ...mapGetters(['isLoggedIn']),
        accountLink() {
            return this.isLoggedIn ? '/order-history' : '/login';
        },
        isAdminRoute() {
            return this.$route.path === '/admin';
        },
        isSmallScreen() {
            return window.innerWidth <= 768;
        }
    },
    methods: {
        showBox(content) {
            this.hoverContent = content
            this.isHovered = true;
            clearTimeout(this.hideTimer);
        },
        startHideTimer() {
            this.hideTimer = setTimeout(() => {
                this.hoverContent = null;
                this.isHovered = false;
            }, 300);
        },
        clearHideTimer() {
            clearTimeout(this.hideTimer);
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        handleResize() {
            if (!this.isSmallScreen) {
                this.menuOpen = false;
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style scoped>
.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 5px 20px; */
    background-color: transparent;
    position: absolute;
    width: 100%;
    z-index: 10;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    box-sizing: border-box;
    transition: background-color 0.5s ease;
}

.navbar.hovered {
    background-color: white;
}

.navbar.hovered .nav-center a {
    color: inherit;
}

.nav-center li:hover {
    border-radius: 5px;
    background-color: #0a57a2;
    color: white;
}

.bi.bi-caret-down-fill {
    display: none;
}

.nav-left-left {
    /* display: flex;
    align-items: center; */
    /* margin-right: 20%; */
}

.nav-left {
    display: flex;
    align-items: center;
}

.logo {
    height: 100px;
    margin-right: 10px;
}

.name-container {
    display: flex;
    flex-direction: column;
}

.tagline {
    font-size: small;
    color: #0a57a2;
    width: max-content;
}

.brand-name {
    font-size: clamp(1.2rem, 1.5vw, 2rem);
    font-weight: bold;
    color: #0a57a2;
    width: max-content;
}

a {
    text-decoration: none;
}

.nav-center {
    display: flex;
    list-style: none;
    margin-right: 10%;
}

.bi {
    color: #0a57a2;
}

.nav-center li {
    padding: 8px 15px;
    position: relative;
    margin: 0 15px;

}

.nav-center a {
    text-decoration: none;
    font-weight: bold;
    font-size: large;
    color: #FFDF00
}

/* Large Div on Hover */
.hover-box {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;
    padding: 20px;
    box-sizing: border-box;
    display: none;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease 0.1s, transform 0.5s ease 0.1s;
}

.nav-center.menu-open {
    animation: slideInFromLeft 0.5s ease forwards;
}

.hover-box.active {
    display: block;
    opacity: 1;
    transform: translateY(0);
    background-color: white;
    pointer-events: all;
    animation: slideDownFadeIn 0.5s forwards;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-right a {
    font-size: 2rem;
    color: black;
}

.menu-toggle {
    display: none;
}

/* Keyframes for slide-down and fade-in effect */
@keyframes slideDownFadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@media (max-width: 768px) {
    .nav-left-left {
        margin-right: 0%;
    }

    .name-container .tagline {
        font-size: x-small;
    }

    .nav-center {
        display: none;
    }

    .nav-center a {
        color: #0a57a2;
    }

    .logo {
        margin-right: 10px;
    }

    .navbar.hovered .nav-center a {
        color: black;
    }

    .nav-right {
        margin-bottom: 5px;
    }

    .menu-toggle {
        display: block;
        font-size: 2rem;
        color: #333;
        border: none;
        background: none;
        cursor: pointer;
    }

    .nav-center.menu-open {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 100vh;
        top: -30%;
        left: 0;
        width: 90%;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .nav-center.menu-open li {
        display: flex;
        justify-content: space-between;
        width: 70%;
    }

    .bi.bi-caret-down-fill {
        display: block;
        color: gray;
    }

    .nav-center li {
        padding: 12px 15px;
    }

    .nav-center li:hover {
        background: none;
    }

    .close-menu {
        margin-left: 80%;
        width: fit-content;
        padding: 10px;
        font-size: 2rem;
        background: none;
        border: none;
        cursor: pointer;
    }

    .hover-box.active {
        margin-top: 45%;
    }
}
</style>
