<template>
    <div class="contact-container">
        <h1>Your Next Clean Starts Here!</h1>
        <div class="contact-item">
            <img src="../assets/icons/location.png" alt="Location Icon" class="contact-icon"/>
            <div class="contact-details">
                <p>9864 Brewster Ln,</p>
                <p>Powell, OH 43065</p>
            </div>
        </div>
        <div class="contact-item">
            <img src="../assets/icons/phone.png" alt="Phone Icon" class="contact-icon"/>
            <div class="contact-details">
                <p>Phone: +1 (614) 218-1183</p>
            </div>
        </div>
        <div class="contact-item">
            <img src="../assets/icons/mail.png" alt="Email Icon" class="contact-icon"/>
            <div class="contact-details">
                <p>Email: maxxcleaners1964@gmail.com</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactContent',
}
</script>

<style scoped>
.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.contact-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.contact-icon {
    width: 32px;
    height: 32px;
    margin-right: 15px;
    object-fit: contain;
}

.contact-details {
    text-align: left;
}

.contact-details p {
    margin: 0;
    font-size: 1rem;
    color: #333;
}

h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}
</style>
